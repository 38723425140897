// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-baltyk-js": () => import("./../../../src/pages/baltyk.js" /* webpackChunkName: "component---src-pages-baltyk-js" */),
  "component---src-pages-cats-js": () => import("./../../../src/pages/cats.js" /* webpackChunkName: "component---src-pages-cats-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jura-js": () => import("./../../../src/pages/jura.js" /* webpackChunkName: "component---src-pages-jura-js" */),
  "component---src-pages-mountains-js": () => import("./../../../src/pages/mountains.js" /* webpackChunkName: "component---src-pages-mountains-js" */),
  "component---src-pages-pets-js": () => import("./../../../src/pages/pets.js" /* webpackChunkName: "component---src-pages-pets-js" */),
  "component---src-pages-poland-js": () => import("./../../../src/pages/poland.js" /* webpackChunkName: "component---src-pages-poland-js" */),
  "component---src-pages-situations-js": () => import("./../../../src/pages/situations.js" /* webpackChunkName: "component---src-pages-situations-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-themes-js": () => import("./../../../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */),
  "component---src-pages-travels-js": () => import("./../../../src/pages/travels.js" /* webpackChunkName: "component---src-pages-travels-js" */),
  "component---src-pages-trees-js": () => import("./../../../src/pages/trees.js" /* webpackChunkName: "component---src-pages-trees-js" */),
  "component---src-pages-water-js": () => import("./../../../src/pages/water.js" /* webpackChunkName: "component---src-pages-water-js" */)
}

